import React from "react";
import './Footer.css'

function Footer() {
    return(
        <div className="footer_container">
            <p>ROS: Ontario, Canada</p>
            <p>Contact: (555) 555-5555</p>
        </div>
    )
}

export default Footer